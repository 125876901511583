import React from 'react';
import Layout from '../components/layout';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import {
  CenteredContentBlock,
  Grid,
  ContentBlock,
  Column,
} from '../components/styleBook';

const EnglishInformationPage = () => {
  return (
    <Layout>
      <section className="information">
        <h1 id="general-information">Company</h1>

        <ContentBlock>
          <p>
            Timeimage Ltd was established in 2003. We rent and repair computers
            and audiovisual devices for companies and private customers. We
            provide our services professionally and we figure out flexible
            solutions depending on the situation at hand.
          </p>
          <p>
            We have more than 15 years of experience of providing services for
            small businesses. Our services include design, implementation,
            configuration and maintenance of the whole workstation and network
            environment. Customer gets ready to use solution.
          </p>
          <p>
            We do maintenance and give quotations for computers, peripheral
            devices and projectors.
          </p>
        </ContentBlock>

        <h2 id="english-contact-information">Contact information</h2>

        <CenteredContentBlock>
          We are open during business days from 9am to 5pm and also during
          weekends if agreed.
        </CenteredContentBlock>

        <Grid>
          <Column>
            <h2>Addresses</h2>
            <p>
              <strong>Office address</strong>
              <br />
              Timeimage Oy
              <br />
              Hämeentie 157, 6th floor,
              <br />
              Loft Office,
              <br />
              00560 Helsinki
              <br />
            </p>

            <p>Business-ID: 2008026-3</p>

            <p>
              <strong>Billing address</strong>
              <br />
              Timeimage Oy
              <br />
              Vaskihuhdantie 4 A 2,
              <br />
              00740 Helsinki
              <br />
            </p>
          </Column>

          <Column>
            <h2>Personnel</h2>

            <p>
              <strong>Arto Kulju</strong>{' '}
              <OutboundLink href="http://fi.linkedin.com/pub/arto-kulju/50/579/a68">
                <img
                  title="Linkedin"
                  src="/images/linkedin.png"
                  alt="Linkedin Arto Kulju"
                  width="20"
                  height="15"
                />
              </OutboundLink>
              <br />
              CEO
              <br />
              Tel. +358 500 434 040
            </p>

            <p>Email addresses in format: firstname.lastname@timeimage.fi</p>

            <p>
              <strong>Maintenance telephone number</strong>
              <br />
              +358 44 504 3404
            </p>
          </Column>
        </Grid>
      </section>
    </Layout>
  );
};

export default EnglishInformationPage;
